import React from 'react';
import AnalystViewWidget from './AnalystViewWidget';

const AnalystView = () => {
  return (
    <div className='m-3'>
      <AnalystViewWidget />
    </div>
  );
};

export default AnalystView;
