import React, { useState } from 'react';
import { Card, Form, Button, Row, Col } from 'react-bootstrap';
import { toast,ToastContainer } from 'react-toastify';
import api from '../../../services/api'; // Ensure this path is correct

export default function OpenTicket() {
  const [previouslyContacted, setPreviouslyContacted] = useState(false);
  const [formData, setFormData] = useState({
    token: '',
    category: '',
    msg: '',
    ticket_number: '',
    reply_by: 'user',
    screen_shot: null,
  });

  const userDetailsString = localStorage.getItem('userDetails');
  const userDetails = userDetailsString ? JSON.parse(userDetailsString) : null;
  const token = userDetails ? userDetails.refreshToken : null;

  const handleRadioChange = (e) => {
    setPreviouslyContacted(e.target.value === 'yes');
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, screen_shot: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = new FormData();
    data.append('token', token);
    data.append('category', formData.category);
    data.append('msg', formData.msg);
    data.append('ticket_number', formData.ticket_number);
    data.append('reply_by', formData.reply_by);
    if (formData.screen_shot) {
      data.append('screen_shot', formData.screen_shot);
    }

    try {
      const response = await api.post('/submit-ticket', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      toast.success(response.data.message);
    } catch (error) {
      toast.error('Failed to submit ticket');
    }
  };

  return (
    <div className="open-ticket-page">
      <h2 className="my-4">Open a Ticket</h2>
      <Row>
        <Col xl={6}>
          <Card className="mb-4">
            <Card.Body>
              <Form>
                <Form.Group controlId="contactedPreviously">
                  <Form.Label>Have you contacted us about this matter previously?</Form.Label>
                  <div>
                    <Form.Check
                      type="radio"
                      label="Yes, I've reached out before"
                      name="previouslyContacted"
                      value="yes"
                      onChange={handleRadioChange}
                      inline
                    />
                    <Form.Check
                      type="radio"
                      label="No, it is a new case"
                      name="previouslyContacted"
                      value="no"
                      onChange={handleRadioChange}
                      inline
                    />
                  </div>
                </Form.Group>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xl={6}>
          <Card>
            <Card.Body>
              <Form onSubmit={handleSubmit}>
                {previouslyContacted && (
                  <Form.Group controlId="ticketNumber" className="mb-3">
                    <Form.Label>Ticket Number</Form.Label>
                    <Form.Control
                      type="text"
                      name="ticket_number"
                      placeholder="Enter your ticket number"
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                )}
                <Form.Group controlId="concern" className="mb-3">
                  <Form.Label>Concern</Form.Label>
                  <Form.Control as="select" name="category" onChange={handleInputChange}>
                    <option>Select Category</option>
                    <option value="Billing">Billing</option>
                    <option value="Technical Support">Technical Support</option>
                    <option value="General Inquiry">General Inquiry</option>
                    <option value="Trading">Trading</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="description" className="mb-3">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="msg"
                    placeholder="Describe your issue"
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <Form.Group controlId="uploadScreenshot" className="mb-3">
                  <Form.Label>Upload Screenshot (Optional)</Form.Label>
                  <Form.Control type="file" name="screen_shot" onChange={handleFileChange} />
                </Form.Group>
                <Button variant="primary" type="submit">Submit Ticket</Button>
              </Form>
            </Card.Body>
            <ToastContainer/>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
