import React from 'react';
import { Card, Col } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';

const SupportCard = ({ title, description, link, linkText }) => {
  const navigate = useNavigate();

  const handleCardClick = () => {
    if (link === "open-ticket") {
      navigate("/open-ticket");
    } else {
      window.location.href = link;
    }
  };

  return (
    <Col xl={3} lg={6} md={6} sm={12} className="mb-4">
      <Card className="support-card h-100" onClick={handleCardClick} style={{ cursor: 'pointer' }}>
        <Card.Body>
          <Card.Title>{title}</Card.Title>
          <Card.Text>{description}</Card.Text>
        </Card.Body>
        {link && (
          <Card.Footer className='text-center'>
            {link && <Link to="#" onClick={handleCardClick} className="btn btn-primary">{linkText}</Link>}
          </Card.Footer>
        )}
      </Card>
    </Col>
  );
};

export default SupportCard;
