import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Modal, Form } from 'react-bootstrap';
import QRCode from 'qrcode.react';
import api from '../../../services/api';

export default function SecuritySettings() {
  const [showModal, setShowModal] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [userName, setUserName] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [is2FAEnabled, setIs2FAEnabled] = useState(false);
  const [qrCode, setQrCode] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [secretKey, setSecretKey] = useState('');

  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  const token = userDetails.refreshToken;

  useEffect(() => {
    fetchUserDetails();
  }, []);

  const fetchUserDetails = async () => {
    try {
      const response = await api.post('user-details', { token });
      if (response.data) {
        setUserName(response.data.name);
        setUserPassword(response.data.password);
        setIs2FAEnabled(response.data.is2FAEnabled);
        setSecretKey(response.data.secretKey);

        if (response.data.is2FAEnabled && response.data.secretKey) {
          generateQrCode(response.data.secretKey);
        }
      }
    } catch (error) {
      console.error('Error fetching user details:', error);
      setError('Failed to fetch user details.');
    }
  };

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const handleChangeNewPassword = (e) => setNewPassword(e.target.value);
  const handleChangeConfirmPassword = (e) => setConfirmPassword(e.target.value);
  const handleChangeVerificationCode = (e) => setVerificationCode(e.target.value);

  const handleSaveChanges = () => {
    if (!newPassword || !confirmPassword) {
      setError('Both new password and confirm password are required.');
      return;
    }

    if (newPassword !== confirmPassword) {
      setError('New password and confirm password must match.');
      return;
    }

    api.post('changePassword', { token, newPassword })
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.error('Error changing password:', error);
      });

    setShowModal(false);
  };

  const handleEnable2FA = async () => {
    try {
      const response = await api.post('enable-2fa', { token });
      if (response.data.qrCode && response.data.secret) {
        setQrCode(response.data.qrCode);
        setSecretKey(response.data.secret);
        setIs2FAEnabled(true);
        window.location.reload(); // Reload the page
      }
    } catch (error) {
      console.error('Error enabling 2FA:', error);
      setError('Failed to enable 2FA.');
    }
  };

  const handleDisable2FA = () => {
    api.post('verify-2fa', { tokenId: token, token: verificationCode, action: 'disable' })
      .then((response) => {
        if (response.data.success) {
          setIs2FAEnabled(false);
          setQrCode('');
          setVerificationCode('');
        } else {
          setError('Invalid 2FA code');
        }
      })
      .catch((error) => {
        console.error('Error disabling 2FA:', error);
        setError('Failed to disable 2FA.');
      });
  };

  const generateQrCode = (secret) => {
    const otpUrl = `otpauth://totp/TradeApp?secret=${secret}&issuer=TradeApp`;
    setQrCode(otpUrl);
  };

  const maskPassword = (password) => {
    if (!password) return '';
    return '*'.repeat(password.length);
  };

  return (
    <div className="mt-4">
      <h2>Authorization</h2>
      <p>Information for logging in to Exness.</p>
      <p>Change your password whenever you think it might have been compromised.</p>

      <div className="border border-primary">
        <Row className="p-4">
          <Col>
            <span>Login</span>
          </Col>
          <Col>
            <span className="text-primary fs-18">{userName}</span>
          </Col>
          <Col></Col>
        </Row>
      </div>

      <div className="border border-primary bm-0">
        <Row className="p-3">
          <Col>
            <span>Password</span>
          </Col>
          <Col>
            <span className="text-primary fs-18">{maskPassword(userPassword)}</span>
          </Col>
          <Col>
            <Button className="me-2" variant="outline-danger" onClick={handleShow}>
              Change
            </Button>
          </Col>
        </Row>
      </div>

      <div className="mt-4">
        <h3>Two-Factor Authentication</h3>
        {!is2FAEnabled ? (
          <>
            <p>2FA is currently disabled on your account.</p>
            <Button variant="success" onClick={handleEnable2FA} className="mt-2">
              Enable 2FA
            </Button>
          </>
        ) : (
          <>
            {qrCode && (
              <div>
                <QRCode value={qrCode} size={200} />
                <Form.Control
                  type="text"
                  placeholder="Enter verification code"
                  value={verificationCode}
                  onChange={handleChangeVerificationCode}
                  className="mt-2"
                />
                <Button variant="danger" onClick={handleDisable2FA} className="mt-2">
                  Disable 2FA
                </Button>
              </div>
            )}
          </>
        )}
      </div>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="newPassword">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter new password"
                value={newPassword}
                onChange={handleChangeNewPassword}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="confirmPassword">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Confirm new password"
                value={confirmPassword}
                onChange={handleChangeConfirmPassword}
              />
            </Form.Group>
          </Form>
          {error && <div className="text-danger">{error}</div>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger light" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveChanges}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
