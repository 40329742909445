import React, { useState,useEffect } from 'react';
import {Link} from 'react-router-dom';
import { Badge,  Dropdown,
} from 'react-bootstrap';
import api from '../../../services/api';

const userDetailsString = localStorage.getItem('userDetails');
const userDetails = userDetailsString ? JSON.parse(userDetailsString) : null;
const token = userDetails ? userDetails.refreshToken : null;

console.log({Token:token});



export default function Demo() {
    const [accounts, setAccounts] = useState([]);

  useEffect(() => {
    api.post('getdemoaccounts',{token})
      .then(response => {
        setAccounts(response.data); // Assuming the response is the array of accounts
      })
      .catch(error => {
        console.error('Failed to fetch accounts:', error);
      });
  }, []);









  return (
    <div className='p-2'>


{accounts.map(account => (

				 <div key={account.id} className="col-lg-12 mb-4 mt-2 ">
                    <div className="card m-0 ">
                        <div className="card-body py-3 py-md-2">

                            <div className="row align-items-center">
                                <div className="col-md-5 mb-3 p-2 m-2">
                                    <div className="media align-items-end">
                                        <div className="bootstrap-badge m-2 p-2">            
                                            <Badge bg="primary" className="m-1">Demo</Badge>
                                            <Badge bg="primary" className="m-1">{account.a_type}</Badge>
                                            <Badge bg="primary" className="m-1">{account.nickname}</Badge>
                                            <Badge bg="primary" className="m-1">#{account.account_number}</Badge>


                                        </div>
                                     </div>
                                </div>
                            </div>

                            <div className="row align-items-center">
                                <div className="col-md-5 mb-3 mb-md-0">
                                    <div className="media align-items-end">
                                        <div className="media-body ms-1">
                                            
                                            <h2 className="mb-0 font-w600 fs-20">{account.balance?account.balance:"0.00"} USD</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-7 text-md-end pb-2">
                                <Link to={`/deposit`} className="btn btn-outline-primary rounded ms-1 btn-sm px-4">Deposit</Link>
                                <Link to={`/withdraw`} className="btn btn-outline-primary rounded ms-1 btn-sm px-4">Withdraw</Link>
                                <Link to={`/trade-terminal?account=${account.a_id}`} className="btn btn-secondary rounded ms-1 btn-sm px-4">Trade</Link>
                                    
                                </div>
                            </div>	

                        </div>
                    </div>
                </div>
                 
                 
                ))}


    </div>
  )
}
