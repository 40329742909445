import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import api from '../../../services/api';

const Summary = () => {
  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  const token = userDetails?.refreshToken;
  
  const [summaryData, setSummaryData] = useState({
    netProfit: 0,
    netBalance: 0,
    closedOrders: 0,
    tradeVolume: 0,
  });

  useEffect(() => {
    const fetchSummaryData = async () => {
      try {
        const response = await api.get('/user-summary', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setSummaryData(response.data);
      } catch (error) {
        console.error('Error fetching summary data:', error);
      }
    };

    if (token) {
      fetchSummaryData();
    }
  }, [token]);

  const iconStyle = { color: '#acb0b8' };

  return (
    <div className="row m-4">
      <div className='col-xl-3 col-xxl-4 col-lg-6 col-sm-6'>
        <div className='widget-stat card'>
          <div className='card-body p-4'>
            <div className='media'>
              <span className='me-3'>
                <i className='flaticon-381-network' style={iconStyle}></i>
              </span>
              <div className='media-body text-white text-end'>
                <p className='mb-1'>Net Profit</p>
                <h4 className='text-white'>${summaryData.netProfit}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='col-xl-3 col-xxl-4 col-lg-6 col-sm-6'>
        <div className='widget-stat card'>
          <div className='card-body p-4'>
            <div className='media'>
              <span className='me-3'>
                <i className='flaticon-381-diamond' style={iconStyle}></i>
              </span>
              <div className='media-body text-white text-end'>
                <p className='mb-1'>Net Balance</p>
                <h4 className='text-white'>${summaryData.netBalance}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='col-xl-3 col-xxl-4 col-lg-6 col-sm-6'>
        <div className='widget-stat card'>
          <div className='card-body p-4'>
            <div className='media'>
              <span className='me-3'>
                <i className='flaticon-381-list' style={iconStyle}></i>
              </span>
              <div className='media-body text-white text-end'>
                <p className='mb-1'>Closed Orders</p>
                <h4 className='text-white'>{summaryData.closedOrders}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='col-xl-3 col-xxl-4 col-lg-6 col-sm-6'>
        <div className='widget-stat card'>
          <div className='card-body p-4'>
            <div className='media'>
              <span className='me-3'>
                <i className='flaticon-381-hourglass' style={iconStyle}></i>
              </span>
              <div className='media-body text-white text-end'>
                <p className='mb-1'>Trade Volume</p>
                <h4 className='text-white'>{summaryData.tradeVolume}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Summary;
