import React, { useState, useEffect } from 'react';
import { Tabs, Tab } from '@material-ui/core';
import { Link, useNavigate } from 'react-router-dom';
import Real from './Real';
import Demo from './Demo';
import api from '../../../services/api'; // Adjust the import according to your project structure
import Swal from 'sweetalert2';
import { Button } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Performance() {
  const [value, setValue] = useState(0);
  const [kycStatus, setKycStatus] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const token = userDetails.refreshToken;

    const fetchKycStatus = async () => {
      try {
        const response = await api.get(`/user-details/${token}`);
        setKycStatus(response.data.kyc);
      } catch (error) {
        console.error('Error fetching KYC status:', error);
      }
    };

    fetchKycStatus();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleNewAccountClick = () => {
    if (kycStatus === 1) {
      navigate('/new-account');
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'Account Verification Required',
        text: 'You need to verify your account first to create a new account.',
        confirmButtonText: 'OK'
      });
    }
  };

  return (
    <>
      <div className="row mb-5 align-items-center">
        <div className="col-lg-9 mb-2">
          <div className="card m-0">
            <div className="card-body py-3 py-md-2 p-2 mb-1">
              <div className="row align-items-center">
                <div className="col-md-5 mb-3 mb-md-0 pr-4 pt-1 pb-1">
                  <div className="media align-items-end">
                    <div className="media-body ms-1">
                      <h1 className="mb-0 font-w600 fs-24 pl-4">My Accounts</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3 mb-4 mb-lg-0 mt-lg-1 mb-1">
          <Button variant="outline-primary" className="btn-lg btn-block rounded" onClick={handleNewAccountClick}>+ New Account</Button>
        </div>
      </div>
      <div className="row">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="nav tabs example"
          role="navigation"
        >
          <Tab label="Real" />
          <Tab label="Demo" />
        </Tabs>
      </div>
      <div>
        {value === 0 && <Real />}
        {value === 1 && <Demo />}
      </div>
      <ToastContainer />
    </>
  );
}
