import React from 'react';
import TradingViewMarketNewsWidget from './TradingViewMarketNewsWidget'; // Adjust the import path as needed

const MarketNews = () => {
  return (
    <div>
      <div className='m-3'>
      <TradingViewMarketNewsWidget />
      </div>
    </div>
  );
};

export default MarketNews;
