import React, { useState, useEffect } from 'react';
import { Form, Button, Card } from 'react-bootstrap';
import api from '../../../services/api'; // Adjust the import according to your project structure
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Profile = () => {
  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  const token = userDetails.refreshToken;
  
  const [userData, setUserData] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    country: '',
    email: '',
    kyc: 0 // Initialize kyc status
  });
  const [code, setCode] = useState('');
  const [step, setStep] = useState(1);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await api.get(`/user-details/${token}`);
        console.log(response.data);
        setUserData({
          firstName: response.data.first_name,
          lastName: response.data.last_name,
          phone: response.data.phone,
          country: response.data.country_name,
          email: response.data.email,
          kyc: response.data.kyc // Set kyc status
        });
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };
    fetchUserDetails();
  }, [token]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData(prevState => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (step === 1) {
      try {
        await api.post('/verify-user', { ...userData, token });
        toast.success('Verification code sent to email.');
        setStep(2);
      } catch (error) {
        console.error('Error sending verification code:', error);
        toast.error('Failed to send verification code.');
      }
    } else if (step === 2) {
      try {
        await api.post('/confirm-code', { token, code });
        toast.success('User verified successfully.');
        // Optionally, reset form or update UI to show verification success
      } catch (error) {
        console.error('Error confirming code:', error);
        toast.error('Failed to verify user.');
      }
    }
  };

  return (
    <div className='col-lg-6 m-3'>
      <Card>
        <Card.Body>
          <div>
            {userData.kyc === 1 ? (
              <h3 className='text-success'>You are verified</h3>
            ) : (
              <h3 className='text-danger'>You are not verified</h3>
            )}
            {step === 1 ? (
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control type="text" name="firstName" value={userData.firstName} onChange={handleChange} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control type="text" name="lastName" value={userData.lastName} onChange={handleChange} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Phone</Form.Label>
                  <Form.Control type="text" name="phone" value={userData.phone} onChange={handleChange} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="email" name="email" value={userData.email} onChange={handleChange} readOnly />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Country</Form.Label>
                  <Form.Control type="text" name="country" value={userData.country} onChange={handleChange} />
                </Form.Group>
                <Button variant="primary" type="submit">Verify</Button>
              </Form>
            ) : (
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label>Verification Code</Form.Label>
                  <Form.Control type="text" name="code" value={code} onChange={(e) => setCode(e.target.value)} />
                </Form.Group>
                <Button variant="primary" type="submit">Submit Code</Button>
              </Form>
            )}
          </div>
        </Card.Body>
      </Card>
      <ToastContainer />
    </div>
  );
};

export default Profile;
