import React, { useEffect, useState } from 'react';
import { Card, Col, Table, Badge, Button } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import api from '../../../services/api'; // Ensure this path is correct

export default function TicketReport() {
  const [tickets, setTickets] = useState([]);
  const [filteredTickets, setFilteredTickets] = useState([]);
  const navigate = useNavigate();

  const userDetailsString = localStorage.getItem('userDetails');
  const userDetails = userDetailsString ? JSON.parse(userDetailsString) : null;
  const token = userDetails ? userDetails.refreshToken : null;

  useEffect(() => {
    fetchTickets();
  }, []);

  const fetchTickets = async () => {
    try {
      const response = await api.get('/user-tickets', {
        params: {
          token,
        },
      });
      const ticketsData = response.data.tickets || [];
      setTickets(ticketsData);
      filterUniqueTickets(ticketsData);
    } catch (error) {
      console.error('Error fetching tickets:', error);
      toast.error('Failed to fetch tickets');
    }
  };

  const filterUniqueTickets = (ticketsData) => {
    const uniqueTickets = [];
    const ticketNumbers = new Set();

    ticketsData.forEach((ticket) => {
      if (!ticketNumbers.has(ticket.ticket_number)) {
        ticketNumbers.add(ticket.ticket_number);
        uniqueTickets.push(ticket);
      }
    });

    setFilteredTickets(uniqueTickets);
  };

  const handleViewMessage = (ticketNumber) => {
    navigate(`/view-message/${ticketNumber}`);
  };

  return (
    <div className="ticket-report-page">
      <h2 className="my-4">Ticket Report</h2>
      <Col lg={12}>
        <Card>
          <Card.Header>
            <Card.Title>Tickets History</Card.Title>
          </Card.Header>
          <Card.Body>
            <Table responsive>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Ticket Number</th>
                  <th>Category</th>
                  <th>Status</th>
                  <th>Created At</th>
                  <th>Updated At</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredTickets.length > 0 ? (
                  filteredTickets.map((ticket, index) => (
                    <tr key={ticket.id}>
                      <td>{index + 1}</td>
                      <td>{ticket.ticket_number}</td>
                      <td>{ticket.category}</td>
                      <td>
                        <Badge bg={ticket.status === 'open' ? 'danger' : 'success'}>
                          {ticket.status}
                        </Badge>
                      </td>
                      <td>{new Date(ticket.created_at).toLocaleString()}</td>
                      <td>{new Date(ticket.updated_at).toLocaleString()}</td>
                      <td>
                        <Button variant="primary" onClick={() => handleViewMessage(ticket.ticket_number)}>View Message</Button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="7" className="text-center">No tickets found</td>
                  </tr>
                )}
              </tbody>
            </Table>
            <ToastContainer />
          </Card.Body>
        </Card>
      </Col>
    </div>
  );
}
