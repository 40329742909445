import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom'
import { loadingToggleAction, loginAction } from '../../store/actions/AuthActions';

import logo from '../../images/logo-2.png'
import login from "../../images/bg-login2.png";
import loginbg from "../../images/bg-login.jpg";
import api from '../../services/api';

function Login(props) {
  const navigate = useNavigate();
  const [email, setEmail] = useState('demo@example.com');
  const [password, setPassword] = useState('123456');
  const [verificationCode, setVerificationCode] = useState(['', '', '', '', '', '']);
  const [is2FAEnabled, setIs2FAEnabled] = useState(false);
  const [show2FAInput, setShow2FAInput] = useState(false);
  let errorsObj = { email: '', password: '', verificationCode: '' };
  const [errors, setErrors] = useState(errorsObj);
  const dispatch = useDispatch();

  function onLogin(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (email === '') {
      errorObj.email = 'Email is Required';
      error = true;
    }
    if (password === '') {
      errorObj.password = 'Password is Required';
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }

    // Check if 2FA is enabled
    api.post('check-2fa', { email })
      .then(response => {
        if (response.data.is2FAEnabled) {
          setIs2FAEnabled(true);
          setShow2FAInput(true);
        } else {
          // If 2FA is not enabled, proceed with login
          dispatch(loadingToggleAction(true));
          dispatch(loginAction(email, password, navigate));
        }
      })
      .catch(error => {
        console.error('Error checking 2FA:', error);
      });
  }

  function verify2FA(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (verificationCode.includes('')) {
      errorObj.verificationCode = 'Verification code is required';
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }

    // Verify 2FA code
    const code = verificationCode.join('');
    api.post('verify-2fa-login', { email, token: code })
      .then(response => {
        if (response.data.success) {
          // If 2FA verification is successful, proceed with login
          dispatch(loadingToggleAction(true));
          dispatch(loginAction(email, password, navigate));
        } else {
          errorObj.verificationCode = 'Invalid verification code';
          setErrors(errorObj);
        }
      })
      .catch(error => {
        console.error('Error verifying 2FA:', error);
      });
  }

  const handleChangeVerificationCode = (e, index) => {
    const newVerificationCode = [...verificationCode];
    newVerificationCode[index] = e.target.value;
    setVerificationCode(newVerificationCode);

    // Move to the next input field
    if (e.target.value && index < verificationCode.length - 1) {
      document.getElementById(`verification-code-${index + 1}`).focus();
    }
  };

  return (
    <div className="login-main-page" style={{ backgroundImage: "url(" + loginbg + ")" }}>
      <div className="login-wrapper">
        <div className="login-aside-left" style={{ backgroundImage: "url(" + login + ")" }}>
          <Link to="/dashboard" className="login-logo">
            <img src={logo} alt="" />
          </Link>
          <div className="login-description">
            <h2 className="mb-2">Check the Status</h2>
            <p className="fs-12">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters,</p>
            <ul className="social-icons mt-4">
              <li><Link to={"#"}><i className="fab fa-facebook-f"></i></Link></li>
              <li><Link to={"#"}><i className="fab fa-twitter"></i></Link></li>
              <li><Link to={"#"}><i className="fab fa-linkedin-in"></i></Link></li>
            </ul>
            <div className="mt-5">
              <Link to={"#"} className=" me-4">Privacy Policy</Link>
              <Link to={"#"} className=" me-4">Contact</Link>
              <Link to={"#"} className="">© 2023 DexignZone</Link>
            </div>
          </div>
        </div>
        <div className="login-aside-right">
          <div className="row m-0 justify-content-center h-100 align-items-center">
            <div className="col-xl-7 col-xxl-7">
              <div className="authincation-content">
                <div className="row no-gutters">
                  <div className="col-xl-12">
                    <div className="auth-form-1">
                      <div className="mb-4">
                        <h3 className="text-primary mb-1">Welcome to Karciz</h3>
                        <p className="">Sign in by entering information below</p>
                      </div>
                      {props.errorMessage && (
                        <div className='bg-red-300 text-red-900 border border-red-900 p-1 my-2'>
                          {props.errorMessage}
                        </div>
                      )}
                      {props.successMessage && (
                        <div className='bg-green-300 text-green-900 border border-green-900 p-1 my-2'>
                          {props.successMessage}
                        </div>
                      )}
                      <form onSubmit={show2FAInput ? verify2FA : onLogin}>
                        <div className="form-group">
                          <label className="mb-2 ">
                            <strong>Email</strong>
                          </label>
                          <input type="email" className="form-control"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          {errors.email && <div className="text-danger fs-12">{errors.email}</div>}
                        </div>
                        <div className="form-group">
                          <label className="mb-2 "><strong>Password</strong></label>
                          <input
                            type="password"
                            className="form-control"
                            value={password}
                            onChange={(e) =>
                              setPassword(e.target.value)
                            }
                          />
                          {errors.password && <div className="text-danger fs-12">{errors.password}</div>}
                        </div>
                        {show2FAInput && (
                          <div className="form-group">
                            <label className="mb-2 "><strong>Verification Code</strong></label>
                            <div className="d-flex">
                              {verificationCode.map((digit, index) => (
                                <input
                                  key={index}
                                  type="text"
                                  maxLength="1"
                                  className="form-control text-center mx-1 p-0"
                                  value={digit}
                                  onChange={(e) => handleChangeVerificationCode(e, index)}
                                  id={`verification-code-${index}`}
                                />
                              ))}
                            </div>
                            {errors.verificationCode && <div className="text-danger fs-12">{errors.verificationCode}</div>}
                          </div>
                        )}
                        <div className="form-row d-flex justify-content-between mt-4 mb-2">
                          <div className="form-group">
                            <div className="form-check custom-checkbox ms-1 ">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="basic_checkbox_1"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="basic_checkbox_1"
                              >
                                Remember my preference
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="text-center">
                          <button
                            type="submit"
                            className="btn btn-primary btn-block"
                          >
                            {show2FAInput ? 'Verify Code' : 'Sign In'}
                          </button>
                        </div>
                      </form>
                      <div className="new-account mt-2">
                        <p className="">
                          Don't have an account?{" "}
                          <Link className="text-primary" to="/page-register">
                            Sign up
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};
export default connect(mapStateToProps)(Login);
